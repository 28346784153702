<template>
  <div>
    <PageHeader>
      <template slot="right">
        <div>
          <b-button
              v-if="$allowPermission('agent:manage.tracking-link')"
              variant="primary"
              to="/tracking-link/create"
          >
            <i class="uil-plus mr-1"></i>
            {{ $t('buttons.add') }}
          </b-button>
        </div>
      </template>
    </PageHeader>

    <div class="card">
      <div class="card-body">
        <h6>
          {{ `${$t('fields.result')} (${total})` }}
        </h6>
        <b-row
            align-v="center"
            class="mb-3"
        >
          <b-col
              cols="12"
              md="3"
          >
            <LimitSelect v-model="selectedLimit"/>
          </b-col>
          <b-col
              cols="12"
              md="9"
          >
            <b-row>
              <b-col cols="6" :offset="!isOwner ? 6 : 0">
                <b-input-group>
                  <b-form-input
                      v-model="search"
                      type="search"
                      class="mx-1"
                      :placeholder="`${$t('fields.search')}... `"
                  ></b-form-input>
                </b-input-group>
              </b-col>
              <b-col v-if="isOwner" cols="3">
                <MasterSelectInput
                    :value="selectedMaster"
                    hide-label
                    @update="onMasterIdChange"
                />
              </b-col>
              <b-col v-if="isOwner" cols="3">
                <AgentsSelectInput
                    :value="selectedAgent"
                    :master-id="selectedMaster"
                    hide-label
                    @update="onAgentIdChange"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-table
            :items="trackingLinkList"
            :fields="fields"
            :busy="isFetching"
            responsive
            show-empty
        >
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(trackingCode)="data">
            <a :href="getTrackingLinkUrl(data.value)" target="_blank">{{ getTrackingLinkUrl(data.value) }}</a>
          </template>
          <template #cell(agent)="data">
            <template v-if="data.value">
              <span v-if="masterNames.includes(data.value.name)" class="badge badge-info">MASTER</span>
              {{ data.value.name }}
            </template>
            <span v-else class="badge badge-info">
              ทุกเอเย่นต์
            </span>
          </template>
          <template #cell(userAgent)="data">
            {{ data.value.name }}
            <template v-if="userInfo.name === data.value.name">
              <br />
              <span class="badge badge-success">เป็นเจ้าของ</span>
            </template>
          </template>
          <template #cell(createdAt)="data">
            {{ data.value | datetime }}
          </template>
          <template #cell(id)="data">
            <b-dropdown
                v-if="$allowPermission('agent:manage.tracking-link')"
                variant="info"
                size="sm"
                dropleft
            >
              <template #button-content>
                <i class="uil uil-cog"></i>
              </template>
              <b-dropdown-item :to="`/tracking-link/detail/${data.item.id}`">
                <i class="uil uil-graph-bar mr-2"></i>
                {{ $t('buttons.detail')}}
              </b-dropdown-item>
              <b-dropdown-item
                  variant="info"
                  :to="`/tracking-link/${data.item.id}`"
              >
                <i class="uil uil-edit-alt mr-2"></i>
                {{ $t('buttons.edit')}}
              </b-dropdown-item>
              <b-dropdown-item
                  variant="danger"
                  @click="onDelete(data.value)"
              >
                <i class="uil uil-trash-alt mr-2"></i>
                <span>
                  {{ $t('buttons.remove')}}
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #empty="">
            <p class="text-center text-muted">{{ $t('messages.nothing_here')}}</p>
          </template>
        </b-table>

        <PaginationInput
            :per-page="limit"
            :total="total"
            @update="(val) => currentPage = val"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  page: {
    title: 'ลิงค์การตลาด',
  },
  components: {
    MasterSelectInput: () => import('@components/master/master-select-input'),
    AgentsSelectInput: () => import('@components/agents/agents-select-input'),
  },
  data() {
    return {
      currentPage: 1,
      selectedLimit: 20,
      selectedAgent: '',
      selectedMaster: '',
      search: '',
      isFetching: false,
      fields: [
        '#',
        { key: 'name', label: 'ชื่อแคมเปญ' },
        { key: 'trackingCode', label: 'ลิงค์' },
        { key: 'click', label: 'จำนวนคลิก' },
        { key: 'uniqueClick', label: 'จำนวนคลิก (ไม่ซ้ำ)' },
        { key: 'createdAt', label: 'สร้างเมื่อ' },
        { key: 'userAgent', label: 'คนสร้าง' },
        {
          key: 'id',
          label: '',
          class: 'text-right',
        },
      ],
      onSearchTimeout: null,
    }
  },
  computed: {
    ...mapGetters(['trackingLinks', 'isAgent', 'isMaster', 'isOwner', 'userInfo']),
    trackingLinkList() {
      const { items } = this.trackingLinks
      return items || []
    },
    pagination() {
      return this.trackingLinks.meta
    },
    limit() {
      return this.pagination.itemsPerPage
    },
    total() {
      return this.pagination.totalItems
    },
    masterNames() {
      return this.userInfo.masters.map((master) => master.name)
    }
  },
  watch: {
    currentPage(val) {
      if (val) {
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    selectedLimit(val) {
      if(val) {
        this.currentPage > 1 ? this.currentPage = 1 : this.fetchData()
      }
    },
    search() {
      this.delaySearch()
    },
    selectedAgent() {
      this.fetchData()
    },
    selectedMaster() {
      this.fetchData()
    }
  },
  created() {
    this.fetchData()
  },
  mounted() {
    if(this.isOwner) {
      this.fields.splice(1, 0, { key: 'agent', label: 'เอเย่นต์' })
    }
  },
  methods: {
    ...mapActions([
      'fetchTrackingLinks',
      'updateTrackingLink',
      'deleteTrackingLink'
    ]),
    async onDelete(id) {
      this.isFetching = true
      await this.deleteTrackingLink(id)
      await this.fetchData()
    },
    async fetchData() {
      this.isFetching = true
      await this.fetchTrackingLinks({
        limit: this.selectedLimit,
        page: this.currentPage,
        search: this.search,
        agentId: this.selectedAgent,
        masterId: this.selectedMaster,
      })
      this.isFetching = false
    },
    getTrackingLinkUrl(trackingCode) {
      const baseUrl = process.env.VUE_APP_TRACKING_BASE_URL.endsWith('/') ? process.env.VUE_APP_TRACKING_BASE_URL : process.env.VUE_APP_TRACKING_BASE_URL + '/'
      return baseUrl + trackingCode
    },
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.fetchData, 800)
    },
    onAgentIdChange(agentId) {
      this.selectedAgent = agentId
    },
    onMasterIdChange(masterId) {
      this.selectedMaster = masterId
    },
  }
}
</script>

<style scoped>

</style>